import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AsyncRequestHelper } from 'gain-lib/ga-async-request/async-request-helper';
import { GaProcessMonitorModule } from 'gain-lib/ga-process-monitor/process-monitor.module';
import { ConfirmDialogResult } from 'gain-lib/confirm-dialog/confirmation-dialog.service';
import { Observable, of } from 'rxjs';

export interface ConfirmationDialogParams<T> {
  title?: string;
  messageHtml: string;
  confirmButtonText: string;
  cancelButtonText: string;
  warn: boolean;
  onConfirm?: () => Promise<T>;
}

export function createConfirmDialogParams<TResult>(options: {
  title?: string | null;
  messageHtml: string;
  confirmButtonText?: string | null;
  cancelButtonText?: string | null;
  warn?: boolean | null;
  onConfirm?: () => Promise<TResult>;
}): ConfirmationDialogParams<TResult> {
  return {
    title: options.title ?? undefined,
    messageHtml: options.messageHtml,
    confirmButtonText: options.confirmButtonText ?? 'Confirm',
    cancelButtonText: options.cancelButtonText ?? 'Cancel',
    warn: options.warn ?? false,
    onConfirm: options.onConfirm,
  };
}

@Component({
  selector: 'gax-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    NgOptimizedImage,
    GaProcessMonitorModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent<T> {
  params: ConfirmationDialogParams<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request?: AsyncRequestHelper<any, T>;

  closeResult: ConfirmDialogResult<T> = {
    action: 'Cancelled',
    confirmed: false,
    cancelled: true,
  };
  readonly pendingOrSuccess$: Observable<boolean>;

  constructor(
    private _dialogRef: MatDialogRef<unknown, ConfirmDialogResult<T>>,
    @Inject(MAT_DIALOG_DATA)
    params?: ConfirmationDialogParams<T>,
  ) {
    if (params == null) {
      throw new Error('ConfirmDialogComponent: ');
    }
    this.params = params;
    if (this.params.onConfirm != null) {
      this.request = new AsyncRequestHelper(this.params.onConfirm);
    }
    this.pendingOrSuccess$ =
      this.request != null
        ? this.request.process.whenPendingOrSuccess()
        : of(false);
  }

  cancel() {
    this._dialogRef.close({
      confirmed: false,
      cancelled: true,
      action: 'Cancelled',
    });
  }

  async confirm() {
    if (this.request != null) {
      const result = await this.request.execute({}).toPromise();
      if (result.success) {
        this._dialogRef.close({
          confirmed: true,
          cancelled: false,
          action: 'Confirmed',
          data: result.result,
        });
      }
    } else {
      this._dialogRef.close({
        confirmed: true,
        cancelled: false,
        action: 'Confirmed',
      });
    }
  }
}
