<gax-page width="full">
  <app-breadcrumbs />
  <gax-page-header>
    <gax-page-header-title>
      <ng-content select="[title]" />
    </gax-page-header-title>
    <gax-page-header-description>
      <ng-content select="[description]" />
    </gax-page-header-description>
  </gax-page-header>

  <gax-page-section>
    <gax-page-section-header>
      <gax-page-section-header-title>
        Your tax rate policy
      </gax-page-section-header-title>
    </gax-page-section-header>
    <gax-page-section-content>
      <div class="tax-rate-policy-wrapper">
        <div class="section section-left">
          <h3>Income Tax</h3>
          <div class="sub-section">
            <div>
              <h4>Residents</h4>
              <span>
                {{ taxRatesData.incomeTaxRatePolicy.residentPolicy }}
              </span>
            </div>

            <div>
              <h4>Non-Residents</h4>
              <span>
                {{ taxRatesData.incomeTaxRatePolicy.nonResidentPolicy }}
              </span>
            </div>
          </div>
        </div>
        <div class="section section-right">
          @if (showSocialTax()) {
            <h3>Social Tax</h3>
            <div class="sub-section">
              <div>
                <h4>Residents</h4>
                <span>
                  {{ taxRatesData.socialTaxRatePolicy.policy }}
                </span>
              </div>

              <div>
                <h4>Non-Residents</h4>
                <span>
                  {{ taxRatesData.socialTaxRatePolicy.policy }}
                </span>
              </div>
            </div>
          }
        </div>
      </div>
    </gax-page-section-content>
  </gax-page-section>

  <gax-page-section>
    <gax-page-section-header class="nav-container">
      <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        @for (link of links; track $index) {
          <a
            mat-tab-link
            (click)="activeLink = link"
            (keyup.enter)="activeLink = link"
            [active]="activeLink === link"
            tabindex="0"
          >
            {{ link }}
          </a>
        }
      </nav>
      <div class="action-container">
        <button
          mat-button
          color="action"
          [matMenuTriggerFor]="overridesMenu"
          [disabled]="!hasTableData"
        >
          <mat-icon>visibility</mat-icon> Overrides
        </button>
        <div class="search-container">
          @if (hasTableData) {
            <mat-icon (click)="toggleSearch(search)">search</mat-icon>
            <input
              #search
              matInput
              [(ngModel)]="dataSources[activeTableIndex].filter"
              placeholder="Search tax rate description"
              [@expandLeft]="showSearch ? 'expanded' : 'collapsed'"
            />
            <mat-icon
              class="clear-icon"
              (click)="dataSources[activeTableIndex].filter = ''"
              [ngClass]="{
                invisible:
                  !showSearch ||
                  dataSources[activeTableIndex].filter.length === 0,
              }"
              >cancel_filled</mat-icon
            >
          } @else {
            <mat-icon class="disabled">search</mat-icon>
          }
        </div>
        <mat-menu #overridesMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="$event.stopPropagation()">
            <mat-checkbox
              [checked]="allPlanTypesSelected"
              [indeterminate]="somePlanTypesSelected"
              (change)="toggleAllPlanTypes($event, selectAllPlanTypes)"
              #selectAllPlanTypes
              >All plan types</mat-checkbox
            >
          </button>
          <mat-divider />
          @if (hasTableData) {
            @for (planType of configuredPlanTypes; track planType.optionValue) {
              <button mat-menu-item (click)="$event.stopPropagation()">
                <mat-checkbox
                  [value]="planType.optionValue"
                  [checked]="
                    displayedColumns[activeTableIndex].includes(
                      planType.optionValue
                    )
                  "
                  (change)="togglePlanType($event, planType.optionValue)"
                  >{{ planType.displayValue
                  }}<mat-icon
                    [ngClass]="{
                      hidden: !showDropdownIcon(planType.optionValue),
                    }"
                    >change_history</mat-icon
                  >
                </mat-checkbox>
              </button>
            }
          }
        </mat-menu>
      </div>
    </gax-page-section-header>

    <gax-page-section-content>
      <mat-tab-nav-panel #tabPanel>
        <gax-mat-table-wrapper
          [dataSource]="hasTableData ? dataSources[activeTableIndex] : []"
        >
          <table
            mat-table
            [dataSource]="hasTableData ? dataSources[activeTableIndex] : []"
          >
            <!-- description Column -->
            <ng-container matColumnDef="description" sticky>
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let band" [attr.rowspan]="band.span">
                @if (band.displayDescription) {
                  <span>{{ band.bandingType }}</span>
                  @if (band.residency !== "Both") {
                    <p>{{ band.residency }}</p>
                  }
                  <span>{{ band.description }}</span>
                  <span>Bands</span>
                }
              </td>
            </ng-container>

            <!-- lowerThreshold Column -->
            <ng-container matColumnDef="lowerThreshold" sticky>
              <th mat-header-cell *matHeaderCellDef>
                Lower Threshold
                @if (currencyCode?.code) {
                  <div>({{ currencyCode?.code }})</div>
                }
              </th>
              <td mat-cell *matCellDef="let group">
                {{ group.lowerThreshold | number: "1.2" }}
              </td>
            </ng-container>

            <!-- upperThreshold Column -->
            <ng-container matColumnDef="upperThreshold" sticky>
              <th mat-header-cell *matHeaderCellDef>
                Upper Threshold
                @if (currencyCode?.code) {
                  <div>({{ currencyCode?.code }})</div>
                }
              </th>
              <td mat-cell *matCellDef="let group">
                {{ (group.upperThreshold | number: "1.2") ?? "\u2014" }}
              </td>
            </ng-container>

            <!-- defaultTaxRate Column -->
            <ng-container matColumnDef="defaultTaxRate">
              <th mat-header-cell *matHeaderCellDef>
                default tax rate
                {{ getDefaultTaxRateHeader(activeTableIndex) }}
                <div>(all award types)</div>
              </th>
              <td mat-cell *matCellDef="let group">
                {{
                  group.taxRateFormula ??
                    (group.defaultTaxRate | percent: "1.0-99")
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="CashBonus">
              <th mat-header-cell *matHeaderCellDef>cash bonus</th>
              <td mat-cell *matCellDef="let band">
                <gax-editable-cell-wrapper [value]="band.CashBonus.newValue">
                  <gax-table-cell-value-edit
                    valueType="percent"
                    [(value)]="band.CashBonus.newValue"
                    (valueChange)="
                      updateOverride(band, PlanType.CashBonus, $event)
                    "
                    [icon]="getIcon(band.CashBonus)"
                    [min]="0"
                    [max]="100"
                  >
                    {{
                      band.CashBonus.newValue ?? band.CashBonus.defaultValue
                        | percent: "1.0-99"
                    }}
                  </gax-table-cell-value-edit>
                </gax-editable-cell-wrapper>
              </td>
            </ng-container>

            <ng-container matColumnDef="RestrictedStockUnit">
              <th mat-header-cell *matHeaderCellDef>rsu</th>
              <td mat-cell *matCellDef="let band">
                <gax-editable-cell-wrapper
                  [value]="band.RestrictedStockUnit.newValue"
                >
                  <gax-table-cell-value-edit
                    valueType="percent"
                    [(value)]="band.RestrictedStockUnit.newValue"
                    (valueChange)="
                      updateOverride(band, PlanType.RestrictedStockUnit, $event)
                    "
                    [icon]="getIcon(band.RestrictedStockUnit)"
                    [min]="0"
                    [max]="100"
                  >
                    {{
                      band.RestrictedStockUnit.newValue ??
                        band.RestrictedStockUnit.defaultValue
                        | percent: "1.0-99"
                    }}
                  </gax-table-cell-value-edit>
                </gax-editable-cell-wrapper>
              </td>
            </ng-container>

            <ng-container matColumnDef="StockOption">
              <th mat-header-cell *matHeaderCellDef>stock option</th>
              <td mat-cell *matCellDef="let band">
                <gax-editable-cell-wrapper [value]="band.StockOption.newValue">
                  <gax-table-cell-value-edit
                    valueType="percent"
                    [(value)]="band.StockOption.newValue"
                    (valueChange)="
                      updateOverride(band, PlanType.StockOption, $event)
                    "
                    [icon]="getIcon(band.StockOption)"
                    [min]="0"
                    [max]="100"
                  >
                    {{
                      band.StockOption.newValue ?? band.StockOption.defaultValue
                        | percent: "1.0-99"
                    }}
                  </gax-table-cell-value-edit>
                </gax-editable-cell-wrapper>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="
                displayedColumns[activeTableIndex];
                sticky: true
              "
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns[activeTableIndex]"
            ></tr>
          </table>
        </gax-mat-table-wrapper>
      </mat-tab-nav-panel>
    </gax-page-section-content>
  </gax-page-section>
</gax-page>

@if (userCanManage) {
  <gax-page-footer>
    <gax-page-footer-actions>
      <button mat-button color="secondary" (click)="cancel()">Cancel</button>
      <button mat-button color="primary" (click)="saveAndContinue()">
        <gax-button-spinner></gax-button-spinner>
        Save & Continue
      </button>
    </gax-page-footer-actions>
  </gax-page-footer>
}
