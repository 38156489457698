<div class="close-button">
  <button mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<img
  ngSrc="/ga-assets/illustrations/question_hires.png"
  alt="Are you sure?"
  height="159"
  width="168"
/>
@if (params.title) {
  <h1>{{ params.title }}</h1>
}
<div mat-dialog-content>
  <div [innerHtml]="params.messageHtml"></div>
</div>
<div mat-dialog-actions align="center">
  <button mat-button color="secondary" (click)="cancel()">
    {{ params.cancelButtonText }}
  </button>
  <button
    mat-button
    [ngClass]="{ pending: pendingOrSuccess$ | async }"
    [color]="params.warn ? 'warning' : 'primary'"
    [disabled]="pendingOrSuccess$ | async"
    (click)="confirm()"
  >
    {{ params.confirmButtonText }}
    <gax-button-spinner></gax-button-spinner>
  </button>
</div>
