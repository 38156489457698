import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogParams,
  ConfirmDialogComponent,
  createConfirmDialogParams,
} from 'projects/gain-lib/confirm-dialog/confirm-dialog.component';

export type ConfirmDialogResult<T> =
  | { action: 'Cancelled'; cancelled: true; confirmed: false }
  | { action: 'Confirmed'; confirmed: true; cancelled: false; data?: T };

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
  constructor(private _matDialog: MatDialog) {}

  /**
   * Displays a confirmation dialog and returns the user's action.
   * @param options - The options for the confirmation dialog.
   * @returns A promise that resolves to a `ConfirmDialogResult` object representing the user's action.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async confirm<T = any>(
    messageHtml: string,
    options?: {
      title?: string | null;
      confirmButtonText?: string | null;
      cancelButtonText?: string | null;
      warn?: boolean | null;
      onConfirm?: () => Promise<T>;
    },
  ): Promise<ConfirmDialogResult<T>> {
    const params = createConfirmDialogParams<T>({
      messageHtml,
      ...options,
    });
    return this._confirm(params);
  }

  private async _confirm<T>(
    params: ConfirmationDialogParams<T>,
  ): Promise<ConfirmDialogResult<T>> {
    const ref = this._matDialog.open<
      ConfirmDialogComponent<T>,
      ConfirmationDialogParams<T>,
      ConfirmDialogResult<T>
    >(ConfirmDialogComponent, {
      minWidth: '520px',
      data: params,
    });

    const dialogResults = await ref.afterClosed().toPromise();
    if (dialogResults != null) {
      return dialogResults;
    } else {
      return {
        action: 'Cancelled',
        cancelled: true,
        confirmed: false,
      };
    }
  }
}
